import React from 'react';
import { Link } from 'gatsby';

import { BaseTemplate } from '@templates';
import { SEO, PageSection } from '@components';

import './index.scss';

const sections = [
  { id: 'section-a', label: 'Section A: General Terms' },
  { id: 'section-b', label: 'Section B: PayMongo Technology' },
  { id: 'section-c', label: 'Section C: Data Usage, Privacy, and Security' },
  { id: 'section-d', label: 'Section D: Use of the Service' },
  { id: 'section-e', label: 'Section E: Additional Legal Terms' }
];

const sidebar = sections.map((section, index) => (
  <li key={`terms-sidebar-item-${index + 1}`} className="sidebar-item">
    <Link className="sidebar-link" to={`/terms#${section.id}`}>
      {`${section.label}`}
    </Link>
  </li>
));

const IndexPage = () => (
  <BaseTemplate>
    <SEO title="Terms of Use" />
    <PageSection className="subpage-terms-of-use">
      <div className="terms-of-use-container">
        <div className="sidebar display-none-is-mobile">
          <h4 className="text-sh700 sidebar-header">On this page</h4>
          <ul className="section-title-list">{sidebar}</ul>
          <h4 className="text-sh700 sidebar-header">Other documents</h4>
          <ul className="section-title-list">
            <li className="sidebar-item">
              <Link className="sidebar-link" to="/privacy">Privacy Policy</Link>
            </li>
            <li className="sidebar-item">
              <Link className="sidebar-link" to="/restricted-businesses">Restricted Businesses</Link>
            </li>
            <li className="sidebar-item">
              <Link className="sidebar-link" to="/third-parties">List of Third Parties</Link>
            </li>
          </ul>
        </div>

        {/* TODO: could be separated per section */}
        <div className="content">
          <div className="title">
            <h2>Terms of Use</h2><br />
            <h4>Last updated: July 19, 2020</h4>
          </div>

          <div id="introduction" className="section">
            <p>Thank you for joining PayMongo!</p>
            <p>
              These Terms of Use (the “Terms” or “Agreement”) cover
              your rights and obligations relating to your access and use of our website, Payment
              Processing Services (as defined below), as well as any other related products and
              services, including our APIs (as defined below) and API documentation (collectively,
              the “Service”). All references to “we”, “us”, “our”, or “PayMongo” refer to PayMongo
              Philippines, Inc., a corporation organized under the laws of the Philippines. All
              references to “you”, “your”, or “user” relate to the person or entity who registered
              an account on PayMongo (an “Account”) to use or access the Services. In addition to
              these Terms, please review our <Link to="/privacy">Privacy Policy</Link>,
              which describes our practices related to collection and use of your information to
              provide the Service. These Terms apply to our Privacy Policy as well.
            </p>
            <p class="content-highlight">By clicking the applicable button or checkbox to indicate
            your acceptance to these Terms and our Privacy Policy, or otherwise by creating an
            Account and using the Service, you represent and agree that you have read, understand,
            and agree to be bound by both these Terms and our Privacy Policy as binding agreements.
            Further, you agree that these Terms and our Privacy Policy apply to your past use,
            if any, of the Service prior to the Effective Date.
            </p>
            <p>
              PLEASE READ THESE TERMS CAREFULLY, AS THEY CONTAIN IMPORTANT INFORMATION ABOUT YOUR
              RIGHTS AND RESPONSIBILITIES, INCLUDING LIMITATION OF OUR LIABILITY AND BINDING
              ARBITRATION. IF YOU DO NOT ACCEPT THIS AGREEMENT IN ITS ENTIRETY, YOU MAY NOT ACCESS
              OR USE THE SERVICE.
            </p>
            <p>
              Do not hesitate to <a href="mailto:legal@paymongo.com">contact us</a> if you have any questions or want
              to discuss either of these important documents.
            </p>
          </div>

          <div id="section-a" className="section">
            <p className="section-header">Section A: General Terms</p>
          </div>

          <div id="overview" className="section">
            <p className="section-title">1. Overview of the Service</p>
            <p>
              The Service fundamentally provides you with the software and connectivity to allow
              real-time secure data transmission for processing debit cards, credit cards and
              certain other available payment methods (each, a “Payment Method”) provided by a
              Payment Method provider (each, a “Payment Method Provider”) on your website or
              application (the “Payment Processing Services”) that allows customers (your
              “Customers”) to purchase your goods and services. Other Payment Methods may include
              e-wallets (including but not limited to GCash, PayMaya, Coins.ph, GrabPay, WeChat Pay,
              and Alipay) and cash-in methods (including but not limited to 7-Eleven Instant Cash
              In, M Lhuiller, and Cebuana Lhuillier). Payment Method Providers may include Visa and
              Mastercard. Your use of a Payment Method may be subject to separate terms applicable
              to the Payment Method, including but not limited to Third-Party Rules (as defined
              below). We may add or remove Payment Method Providers and Acquirers (as defined below)
              at any time.
            </p>
            <p>
              Subject to these Terms, users that create an Account through the Services and complete
              our verification requirements, as set forth below, will be able to use the Payment
              Processing Services. The Services also include software, APIs and any other technology
              as described on the PayMongo website.
            </p>
          </div>

          <div id="age-policy" className="section">
            <p className="section-title">2. Age Policy; Permitted Users</p>
            <p>
              We do not intend that the Service be used by anyone under 18 years old. Thus, by
              creating an Account and using the Service, you represent and warrant that you are at
              least 18 years old and can validly transact on your own behalf. If we learn or have
              reason to suspect that a user is under 18 years of age, we will promptly revoke the
              user’s access to the Service and delete any personally identifiable information
              submitted by that user. Further, you represent and warrant that your use of the
              Service does not and will not conflict with any pre-existing obligation in conflict or
              in any way inconsistent with the provisions of these Terms.
            </p>
          </div>

          <div id="acct-info" className="section">
            <p className="section-title">3. Your Account and Your Information</p>
            <p className="subsection-title">3.1 You Must Register for an Account</p>
            <p>
              To access our Payment Processing Services, you will need to register an Account using
              your first and last name, e-mail address, and the name of your business (please see
              our <Link to="/privacy">Privacy Policy</Link> for more information on our
              practices related to collection and use of your data), and you agree to our use of
              such information. You agree to provide accurate, current, and complete information
              during the registration process and to update such information to keep it up to date.
              We reserve the right to suspend or terminate your Account according to these Terms if
              we determine that any information provided by you is inaccurate or incomplete.
            </p>
            <p>
              As part of the Account registration process, you will also be asked to create a
              password and a business handle (i.e. an identifier of your business which will be part
              of the URL that you create and share with your Customers) (your “Handle”). Your
              business name, Handle, and/or the URL may appear on your Customers’ bank or other
              statements. To minimize confusion and avoid potential disputes, these descriptors must
              be recognizable to your Customers and must accurately describe your business or
              activities.
            </p>
            <p>
              By registering for an Account on behalf of an entity, you represent and warrant that
              you have authority to (i) accept these Terms on behalf of and bind such entity, and
              (ii) provide information on behalf of such entity. We may require you to provide
              additional information or documentation demonstrating such authority to bind the
              entity.
            </p>
            <p>
              Without our express written consent, you may not register or attempt to register for
              an Account on behalf of a user of PayMongo whose Account was previously terminated and
              is otherwise barred from further use of the Services.
            </p>
            <p className="subsection-title">3.2 You Must Safeguard Your Account</p>
            <p>
              If you become aware that an unauthorized third party is using your information to
              access the Service, you must promptly notify us of such use. You agree that we will
              not be liable for any loss or harm that you incur if someone else uses your
              information to access the Service, either with or without your knowledge, and that you
              will be solely responsible for such use, whether or not you authorized the use. You
              are responsible for safeguarding your Account information, including your password.
              You agree not to disclose your password to, or share your Account with, any third
              party. You are solely responsible for maintaining and protecting your username and
              password.
            </p>
            <p className="subsection-title">3.3 Maintaining Your Account Current</p>
            <p>
              You agree to keep the information in your Account current. You must promptly update
              your Account with any changes affecting you, the nature of your business activities,
              beneficial owners, principals, changes to the legal entity that operates your business
              (for example, if you incorporate an entity), or any other pertinent information. We
              may suspend your Account or terminate this Agreement if you fail to keep this
              information current.
            </p>
            <p className="subsection-title">3.4 We Will Verify Your Identity</p>
            <p>
              As a provider of payment services, we are required to comply with many regulations,
              including anti-money laundering laws. As such, we are required to obtain information
              about and verify the identity of our users. To comply with our obligations under
              applicable laws, we will request that you provide certain information to us about you
              after you create your Account. This information will be used by us for the purposes of
              identity verification and the detection of money laundering, terrorist financing,
              fraud, or any other financial crimes. You agree that all such information provided by
              you will be complete and accurate, that you will keep us updated if any of the
              information you provided changes, and that we may keep a record of such information
              consistent with our internal policies.
            </p>
            <p>
              In addition to collecting information from you, we are required to take reasonable
              steps to verify your identity as a user. You expressly authorize us to take any and
              all actions that we reasonably deem necessary to verify your identity or protect you
              and/or us against fraud or other financial crime. These may include, but are not
              limited to, engaging third-party services to assist with such verification efforts,
              sharing your information with such third parties, and collecting additional
              information about you from such third parties. Any such third party to whom we
              disclose or transfer information about you for the above purpose is contractually
              required to comply with confidentiality standards and establish adequate safeguards
              for data privacy, undertake to respect any user’s right to privacy and comply with
              applicable laws. We also require that said third parties use information shared with
              them only for the above purposes and follow our reasonable directions with respect to
              this information. Please refer to the <Link to="/third-parties">List of Third
              Parties</Link> engaged by PayMongo for this purpose.
              Please also refer to our <Link to="/privacy">Privacy Policy</Link> for further details.
            </p>
            <p>
              Until you have submitted all required information, and subject to our review and
              approval, your Account will be available to you on a preliminary basis only (i.e.
              although users may be allowed to conduct test transactions to ensure proper
              integration with our Payment Processing Services, users will not be able to conduct
              Transactions), and we may terminate it at any time and for any reason.
            </p>
            <p className="subsection-title">
              3.5 Sharing Your Information with Payment Method Providers and Acquirers
            </p>
            <p>
              Throughout the term of this Agreement, we may share information about your Account
              with Payment Method Providers and Acquirers (as defined below) in order to verify your
              eligibility to use the Payment Processing Services, establish any necessary accounts
              or credit with Payment Method Providers and Acquirers, monitor Charges (as defined
              below) and other activity, and conduct risk management and compliance reviews. We may
              also share your personally identifiable information with Payment Method Providers and
              Acquirers for the purpose of facilitating the compliance of PayMongo, the Payment
              Method Providers, and the Acquirers with applicable laws and regulations, as well as
              any rules, guidelines, or by-laws of any of the Payment Method Providers and/or
              Acquirers (“Third-Party Rules”). Any Payment Method Provider or Acquirer to whom we
              disclose information about your Account is contractually required to comply with
              confidentiality standards and establish adequate safeguards for data privacy,
              undertake to respect any user’s right to privacy and comply with applicable laws. We
              also require that these Payment Method Providers or Acquirers use information shared
              with them only for the above purposes and follow our reasonable directions with
              respect to this information. Please refer to the <Link to="/third-parties">List of
              Third Parties</Link> for the list of Payment Method Providers or Acquirers who will
              have access to information about your account.
              Please also refer to our <Link to="/privacy">Privacy Policy</Link> for further details.
            </p>
            <p className="subsection-title">3.6 Additional Verification</p>
            <p>
              In addition to the above, at any time during your use of the Services, we may require
              additional information from you to verify beneficial ownership or control of the
              business, validate information you provided, verify your identity, and assess the risk
              associated with your business. This additional information may include phone numbers,
              physical and mailing addresses for you and your business, your business’s tax
              identification number, business invoices, copies of government-issued identification,
              business licenses, or other information related to your business, its beneficial
              owners or principals. We may also request that you provide copies of financial
              statements or records pertaining to your compliance with this Agreement or require you
              to provide a personal or company guarantee. Your failure to provide this information
              or material may result in suspension or termination of your Account. We may share this
              information, including any other personally identifiable information you provide to
              us, with our third-party vendors we use to comply with many regulations, including
              anti-money laundering laws, subject to compliance with same requirements and
              limitations as provided in Clause 3.4 above.
            </p>
          </div>

          <div id="section-b" className="section">
            <p className="section-header">Section B: PayMongo Technology</p>
          </div>

          <div id="pay-processing" className="section">
            <p className="section-title">1. Payment Processing Services</p>
            <p className="subsection-title">1.1 Overview of the Payment Processing Services</p>
            <p>
              The Payment Processing Services give you the ability to accept Payment Methods from
              your Customers in exchange for your goods or services. In order to provide you with
              access to the Payment Methods and Payment Processing Services, we work with Payment
              Method Providers, Acquirers (as defined below), and other third parties. Below please
              find a list of defined terms used throughout this Agreement:
            </p>
            <ul>
              <li key="defined-terms-1">
                <span class="defined-term">Acquirer</span> means a financial institution that is authorized by a Payment Method
                Provider to accept Charges on behalf of the Payment Method Provider;
              </li>
              <li key="defined-terms-2">
                <span class="defined-term">Charge</span> means a credit or debit instruction to capture funds from an account that a
                Customer maintains with a bank or other financial institution in connection with a
                Transaction;
              </li>
              <li key="defined-terms-3">
                <span class="defined-term">Chargeback</span> means an instruction initiated by a Customer for the return of funds
                for an existing Charge;
              </li>
              <li key="defined-terms-4">
                <span class="defined-term">Payout</span> means the amount due to you from your Transactions minus our Fees and any
                Fines, Refunds, Chargebacks, Reversals, setoffs, recoupments or any other amounts
                due to PayMongo;
              </li>
              <li key="defined-terms-5">
                <span class="defined-term">Refund</span> means a refund issued by you through the Payment Processing Services;
              </li>
              <li key="defined-terms-6">
                <span class="defined-term">Reserve</span> means an amount or percentage of your Payouts that PayMongo holds in order
                to protect against the risk of Reversals, Chargebacks, or any other risk, exposure
                and/or potential liability to us related to your use of the Payment Processing
                Services; and
              </li>
              <li key="defined-terms-7">
                <span class="defined-term">Reversal</span> means an instruction initiated by a Payment Method Provider, an Acquirer,
                or PayMongo to return funds for an existing Charge.
              </li>
            </ul>
            <p className="subsection-title">1.2 Your Use of the Payment Processing Services</p>
            <p>
              You may only use Payment Processing Services to facilitate a purchase, sale, donation,
              order, or other transaction (each a “Transaction”) with your Customers. You may not
              use Payment Processing Services to send money to others, to conduct any personal
              transactions, or for any other purposes prohibited by this Agreement. You represent
              and warrant to PayMongo that each Transaction that you process using the Payment
              Processing Services is solely in payment for your provision or sale of bona fide goods
              or services (including charitable services) to your Customers.
            </p>
            <p className="subsection-title">1.3 Restricted Business Activities</p>
            <p>
              You must use the Services in a lawful manner, and must obey all laws, rules, and
              regulations applicable to your use of the Services and to Transactions. You may not
              use the Services to enable any person (including you) to benefit from any activities
              PayMongo has identified as a restricted business or activity (collectively,
              “Restricted Businesses”). Restricted Businesses include use of the Services in or
              for the benefit of a country, organization, entity, or person embargoed or blocked by
              any government, including those on sanctions lists identified by
              the <a href="https://www.treasury.gov/resource-center/sanctions">United
              States Office of Foreign Asset Control (OFAC)</a>. Restricted Businesses shall also
              include, but not be limited to, any party participating in:
            </p>
            <ul>
              <li key="restricted-business-1">
                Any activities that violate these Terms, any Third-Party Rules, or any other
                agreement that you have entered into with us or any of our agents in connection with
                the Payment Processing Services;
              </li>
              <li key="restricted-business-2">Any activities that violate applicable law;</li>
              <li key="restricted-business-3">Investment and credit services;</li>
              <li key="restricted-business-4">
                Money and legal services, including money transmitters and money services
                businesses, check cashing businesses, currency exchanges or dealers, bill-pay
                services, crowdfunding, insurance, bail bonds, collections agencies, law firms
                collecting funds for any purpose other than to pay fees owed to the firm for
                services provided by the firm;
              </li>
              <li key="restricted-business-5">
                Virtual currency, including virtual currency that can be monetized, resold, or
                converted to physical or digital products and services or otherwise exit the virtual
                world (for example, Bitcoin);
              </li>
              <li key="restricted-business-6">
                Gambling, including internet gambling, lotteries, bidding fee auctions, sports
                betting, internet gaming, contests, sweepstakes, games of chance including legal or
                illegal forms of gambling;
              </li>
              <li key="restricted-business-7">
                Adult services, including pornography and other obscene materials (including
                literature, imagery and other media) depicting nudity or explicitly sexual acts,
                sites offering any sexually-related services such as prostitution, escorts, pay-per
                view, adult live chat features, sexually oriented items (for example, adult toys),
                adult video stores and sexually oriented massage parlors, gentleman’s clubs, topless
                bars, and strip clubs, sexually oriented dating services;
              </li>
              <li key="restricted-business-8">Illegal drugs or drug paraphernalia;</li>
              <li key="restricted-business-9">
                Infringement of Intellectual property or proprietary rights; counterfeit or
                unauthorized goods;
              </li>
              <li key="restricted-business-10">The sale of illegal products or services;</li>
              <li key="restricted-business-11">
                Unfair, predatory, or deceptive practices, including pyramid or “get-rich-quick”
                schemes; and
              </li>
              <li key="restricted-business-12">
                Any products or services that are otherwise restricted by third parties we work with
                in providing you with the Service, including but not limited to Payment Method
                Providers.
              </li>
            </ul>
            <p>
              Please review the list of <Link to="/restricted-businesses">Restricted
              Businesses</Link> thoroughly before registering for and opening an Account.
              If you are uncertain  whether a category of business or activity is restricted or have
              questions about how these restrictions apply to you,
              please <a href="mailto:legal@paymongo.com">contact us</a>. We may add to or update
              the Restricted Business List at any time.
            </p>
            <p className="subsection-title">1.4 Reversals, Refunds, and Chargebacks</p>
            <p>
              You may only submit Charges through the Payment Processing Services that are
              authorized by your Customers. To enable us to process Transactions for you, you
              authorize and direct us, our affiliates, the Payment Method Providers and Acquirers to
              receive and settle any amounts due to you from your Transactions through the Payment
              Processing Services. You may not grant or assign any interest in the amounts due to
              you from your Transactions to any third party until such time as such amounts are
              deposited into your Bank Account (as defined below). You appoint PayMongo as your
              agent for the limited purpose of directing, receiving, holding and settling such
              amounts. You agree that PayMongo’s receipt of such amounts satisfies the relevant
              end-customer’s obligations to make payments to you. We will promptly update your
              Account balance to reflect any such amounts that we receive on your behalf.
            </p>
            <p>
              You understand that even authorized Transactions may be subject to a Chargeback. We
              are not responsible for or liable to you for authorized and completed Charges that are
              later the subject of a Chargeback, Refund, or Reversal, are submitted without
              authorization or in error, or violate any applicable laws or regulations.
            </p>
            <p>
              You are immediately responsible to us for all Chargebacks, Refunds, Reversals, or
              Fines (as defined below) regardless of the reason or timing. We may decline to act
              upon a Refund instruction, or delay execution of the instruction, if: (i) it would
              cause your Account balance to become negative; (ii) you are the subject of bankruptcy
              proceedings; or (iii) where we otherwise believe that there is a risk that you will
              not meet your liabilities under this Agreement (including with respect to the Charge
              that is the subject of the Refund instruction).
            </p>
            <p>
              PayMongo is not a bank and we do not accept deposits, provide loans or extend credit.
              If you accept payment for products or services not immediately deliverable to the
              Customer, we may, in our sole discretion, initiate a Reversal. Reversals may also
              result from (i) invalidation of a charge by a Payment Method Provider or an Acquirer;
              (ii) funds settled to you in error or without authorization; and (iii) submission of a
              Charge in violation of this Agreement, Third-Party Rules, and/or applicable laws and
              regulations.
            </p>
            <p>
              Please note that Payment Method Providers and/or Acquirers may also initiate
              Reversals.
            </p>
            <p>
              You may be able to challenge a Chargeback by submitting evidence to us through various
              channels, including email or as otherwise indicated by PayMongo. In the future, we may
              also allow you submit such evidence through the PayMongo Dashboard (the “Dashboard”).
              We may request additional information to provide to Payment Method Providers and
              Acquirers to assist you in contesting the Chargeback, but we cannot guarantee that
              your challenge will be successful. Payment Method Providers and Acquirers may deny
              your challenge for any reason they deem appropriate. You may not submit a new Charge
              which duplicates a Transaction that is subject to a Chargeback.
            </p>
            <p>
              You are liable for all losses you incur when lost or stolen payment credentials or
              accounts are used to purchase products or services from you. PayMongo does not and
              will not insure you against losses caused by fraud under any circumstances.
            </p>
            <p className="subsection-title">1.5 Support</p>
            <p>
              PayMongo will provide you with support in the event you have general issues relating
              to your Account or your use of the Payment Processing Services. We may make this
              support available to you via our website or our API documentation. If you cannot
              resolve the issues you are having with the support we make available to you via our
              website or our API documentation, please feel free
              to <a href="mailto:support@paymongo.com">contact us</a>.
            </p>
            <p className="subsection-title">1.6 Fees and Penalties</p>
            <p>
              PayMongo will provide the Payment Processing Services to you at the rates and for the
              fees (the “Fees”) described on the PayMongo website. We may revise the Fees at any
              time. In case Fees are revised, PayMongo will provide you with no less than 30 days
              advance notice of such change.
            </p>
            <p>
              In addition to the Fees, you are also responsible for any penalties or fines imposed
              on you in relation to your Account by PayMongo or any Payment Method Provider or
              Payment Method Acquirer resulting from your use of Payment Processing Services in a
              manner not permitted by this Agreement or a Payment Method Provider’s Third-Party
              Rules (each such fine or penalty, a “Fine”).
            </p>
            <p className="subsection-title">1.7 Taxes</p>
            <p>
              Our fees are exclusive of any applicable Taxes, except as expressly stated to the
              contrary. You shall pay, indemnify, and hold PayMongo harmless from (i) any sales,
              use, excise, import or export, value-added, or similar tax or duty, and any other tax
              or duty not based on PayMongo’s income, and (ii) all government permit fees, customs
              fees and similar fees which PayMongo may incur with respect to this Agreement. Such
              taxes, fees and duties paid by you shall not be considered a part of, a deduction
              from, or an offset against, payments due to PayMongo hereunder.
            </p>
            <p>
              Additionally, you understand that we may send documents to you and tax authorities for
              Transactions processed using the Services. Pursuant to applicable law, we may be
              required to file periodic informational return with taxing authorities in relation to
              your use of the Payment Processing Services.
            </p>
            <p className="subsection-title">1.8 Settlement of Accounts</p>
            <p>
              Subject to the terms of this Agreement, PayMongo will instruct the Acquirer to remit
              to the bank or other financial institution account that you designate (your “Bank
              Account”), all amounts due to you from your Transactions, minus any Fees, Fines,
              Reversals, Chargebacks, Refunds, setoffs, recoupments or other amounts that you owe to
              PayMongo under this Agreement. You affirm that you are authorized to initiate
              settlements to and debits from your Bank Account, and that your Bank Account is owned
              by you. If you update your Bank Account, then you must ensure that you continue to
              comply with the requirements of this section. We may require you to provide us with
              documentary proof demonstrating your compliance with this section, and your failure to
              provide such proof will constitute a breach of this Agreement.
            </p>
            <p>
              If the Payout is not sufficient to cover the amounts due, without limiting our other
              rights and remedies (all of which are cumulative), you agree that we may debit your
              Bank Account for the applicable amounts, and/or set-off the applicable amounts against
              future Payouts. Upon our request, you agree to provide us with all necessary bank
              account routing and related information and grant us permission to debit amounts due
              from your Bank Account.
            </p>
            <p>
              We may require a holding period before making initial settlement to your Bank Account.
            </p>
            <p>
              Your Payout Schedule (i.e. the time it takes for PayMongo to initiate settlement to
              your Bank Account) will be specified on your Dashboard, or as otherwise designated by
              PayMongo. Please be aware that a Payment Method Provider, an Acquirer, or the
              financial institution holding your Bank Account may delay settlement for any reason
              (and thus delay your Payout Schedule), and we are not responsible for any harm
              suffered by you stemming from such delay. We are not responsible for any action taken
              by the institution holding your Bank Account to not credit your Bank Account or to
              otherwise not make funds available to you as you expected.
            </p>
            <p>
              We reserve the right to suspend settlement to you. Examples of situations where we may
              do so are: (i) where there are pending, anticipated, or excessive Chargebacks,
              Refunds, or Reversals; (ii) in the event that we suspect or become aware of suspicious
              activity; or (iii) where we are required by applicable law or court order. We have the
              right to withhold settlement to your Bank Account upon termination of this Agreement
              if we reasonably determine that we may incur losses resulting from credit, fraud, or
              other legal risks associated with your Account. If we exercise our right to withhold a
              Payout for any reason, we will communicate the general reason for withholding the
              Payout and give you a timeline for releasing the funds.
            </p>
            <p className="subsection-title">1.9 Reserves</p>
            <p>
              PayMongo, in its sole discretion, may place a Reserve on a portion of your Payouts in
              the event that we believe that there is a high level of risk associated with your
              business. If we place a reserve on your Payouts, we will provide you with notice
              specifying the terms of the Reserve. The terms may require that a certain percentage
              of your Payouts are held for a certain period of time, that a fixed amount of your
              Payouts are withheld from payout to you, or such other restrictions that PayMongo
              determines in its sole discretion. PayMongo may change the terms of the Reserve at any
              time by providing you with notice of the new terms.
            </p>
            <p>
              PayMongo may hold a Reserve as long as it deems necessary, in its sole discretion, to
              mitigate any risks related to your Transactions. You agree that you will remain liable
              for all obligations related to your Transactions even after the release of any
              Reserve. In addition, we may require you to keep your Bank Account available for any
              open settlements, Chargebacks and other adjustments.
            </p>
            <p className="subsection-title">1.10 Security Interest</p>
            <p>
              As security for the payment and performance of all of your now existing or hereinafter
              arising or incurred obligations under this Agreement or any other obligation to
              PayMongo, you grant to PayMongo a lien on, and security interest in and to, all your
              now existing or hereafter right, title and/or interest in, to or under this Agreement,
              the Reserve, all funds held in Reserve, all Reserves at any time established in
              accordance with this Agreement, and all proceeds of any of the foregoing. You agree to
              duly execute and deliver to PayMongo such instruments and documents as may be required
              to perfect and otherwise give effect to the lien and security interest granted to
              PayMongo under this Agreement.
            </p>
            <p className="subsection-title">1.11 Dormancy</p>
            <p>
              If you leave any funds dormant in your Account for a period of ten (10) years and you
              do not give us instructions where to send them, we may be required by applicable law
              to deem the funds to be abandoned by you, and to deliver them to the Philippine
              government. To the extent required by applicable law, we will attempt to provide you
              with notice if we hold funds payable to you in an account beyond the applicable
              dormancy period for abandoned property.
            </p>
            <p className="subsection-title">1.12 Suspension or Refusal of Transactions</p>
            <p>
              PayMongo may refuse, condition, or suspend any Transactions that we believe: (i) may
              violate this Agreement or other agreements you may have with us; (ii) are
              unauthorized, fraudulent or illegal; or (iii) expose you, PayMongo, or others to risks
              unacceptable to PayMongo. If we suspect or know that you are using or have used the
              Services for unauthorized, fraudulent, or illegal purposes, we may share any
              information related to such activity with the appropriate financial institution,
              regulatory authority, or law enforcement agency consistent with our legal obligations.
              This information may include information about you, your Account, your Customers, and
              Transactions made through your use of the Payment Processing Services.
            </p>
          </div>

          <div id="customers" className="section">
            <p className="section-title">2. Your Customers</p>
            <p>
              You may only use our Payment Processing Services to conduct legitimate Transactions
              with your Customers. You are responsible for your relationship with your Customers. We
              are not responsible for the products or services you publicize or sell, or that your
              Customers purchase using the Payment Processing Services; or if you accept donations,
              for your communication to your Customers of the intended use of such donations. You
              understand and affirm that you are solely responsible for the nature and quality of
              the products or services you provide, and for delivery, support, refunds, returns, and
              for any other ancillary services you provide to your Customers.
            </p>
            <p>
              Although we provide you with our Payment Processing Services, we have no way of
              knowing if any Transaction is accurate or complete, or normal for your business in
              particular or your type of business. Only you are responsible for knowing whether a
              Transaction initiated by your Customer is erroneous (such as a Customer purchasing one
              item when they meant to order another) or suspicious (such as unusual or large
              purchases, or a request for delivery to a foreign country where this typically does
              not occur). If you are unsure if a Transaction is erroneous or suspicious, you agree
              to research the Transaction and, if necessary, contact your Customer before fulfilling
              or completing the Transaction. You are solely responsible for any losses you incur due
              to erroneous or fraudulent Transactions in connection with your use of the Services.
            </p>
            <p>
              Except where PayMongo and a Customer have otherwise agreed, you maintain the direct
              relationship with your Customers and are responsible for: (i) acquiring appropriate
              consent to submit Charges through the Payment Processing Services on their behalf;
              (ii) providing confirmation or receipts to Customers for each Charge; (iii) verifying
              Customers’ identities; and (iv) determining a Customer’s eligibility and authority to
              complete Transactions.
            </p>
            <p>
              Customers must understand the purpose, amount, and conditions of Charges you submit
              through the Payment Processing Services. When using the Payment Processing Services
              you agree to: (i) accurately communicate, and not misrepresent, the nature of the
              Transaction, and the amount of the Charge in the appropriate currency prior to
              submitting it to the API; (ii) provide a receipt that accurately describes each
              Transaction to Customers; (iii) provide Customers a meaningful way to contact you in
              the event that the product or service is not provided as described; (iv) not use
              Services to sell products or services in a manner that is unfair or deceptive, exposes
              Customers to unreasonable risks, or does not disclose material terms of a purchase in
              advance; and (v) inform Customers that PayMongo and its affiliates process
              Transactions (including payment Transactions) for you. You also agree to maintain and
              make available to your Customers a fair and neutral return, refund, cancellation, or
              adjustment policy, and clearly explain the process by which Customers can receive a
              Refund.
            </p>
            <p>
              You hereby agree to undertake, and you acknowledge and understand that it is your sole
              responsibility, to (1) obtain your Customers’ consent to these Terms and the Privacy
              Policy and (2) provide all notices and obtain all consents necessary for PayMongo’s
              use of Customer Data (as defined below).
            </p>
          </div>

          <div id="api" className="section">
            <p className="section-title">3. Application Programming Interface</p>
            <p>
              PayMongo will provide you access to its Application Programming Interface (“APIs”)
              that you will use to access the Payment Processing Services. You may use the APIs
              solely as described in the API documentation or as otherwise communicated to you by
              us. We may update the APIs and API documentation from time to time, and may add or
              remove functionality. To the extent possible, we will communicate to you any changes
              in functionality.
            </p>
            <p>
              You will be provided with API keys, and you can manage these keys from the Dashboard.
              Public keys are meant to be used for transactions that are accessed through public
              channels (e.g. generating tokens for credit card numbers from your web application),
              and secret keys are for transactions that are done internally in your systems (e.g.
              creating an actual charge). You are responsible for securing your secret keys – do not
              publish or share them with any unauthorized persons. Failure to secure your secret
              keys will increase the likelihood of fraud on your Account and potential losses to you
              or your Customers. You should contact us immediately if you become aware of any
              unauthorized use of your secret key or any other breach of security regarding the
              Services. We will provide more details on proper use of public and secret API keys in
              the API documentation.
            </p>
          </div>

          <div id="license" className="section">
            <p className="section-title">4. PayMongo's Intellectual Property & Licenses</p>
            <p className="subsection-title">4.1 Ownership of Our IP</p>
            <p>
              PayMongo owns all rights, title, and interest in the patents, copyrights (including
              rights in derivative works), moral rights, rights of publicity, trademarks or service
              marks, logos and designs, trade secrets, and other intellectual property embodied by,
              or contained in the API and API documentation, Services (including all files and
              content), and the Dashboard (collectively, “Our IP”) or any copies thereof. Our IP is
              protected by copyright, trade secret, trademark, patent, and other intellectual
              property laws, and all rights in Our IP not expressly granted to you in this Agreement
              are reserved. To be clear, other than the express licenses granted by this Agreement,
              PayMongo grants no right or license by implication, estoppel or otherwise to Our IP.
              You may not sell, redistribute, download, export, or reproduce Our IP in any way. You
              also may not decompile, reverse-engineer, disassemble, or otherwise convert Our IP
              without our permission.
            </p>
            <p>
              You may choose to or we may invite you to submit comments or ideas about improvements
              to the Service, our API, our platform, or any other component of our products or
              services (“Ideas”). If you submit an Idea to us, we will presume that your submission
              was voluntary, unsolicited by us, and delivered to us without any restrictions on our
              use of the Idea. You also agree that PayMongo has no fiduciary or any other obligation
              to you in connection with any Idea you submit to us, and that we are free to use your
              Ideas without any attribution or compensation to you. You also agree that we do not
              waive any rights to use similar or related Ideas previously known to us, developed by
              our employees or contractors, or obtained from other sources.
            </p>
            <p className="subsection-title">4.2 Software License</p>
            <p>
              PayMongo hereby grants you a revocable, non-exclusive, and non-transferable limited
              license to access and/or use PayMongo’s APIs, developer’s toolkit, and other software
              in accordance with the accompanying API documentation made available by PayMongo for
              purposes of using the Payment Processing Services. This license grant includes all
              updates, upgrades, new versions and replacement software for your use in connection
              with the Payment Processing Services. If you do not comply with these Terms, the API
              documentation, and any other requirements provided by PayMongo, then you will be
              liable for all resulting damages suffered by you, PayMongo and third parties. Upon
              expiration or termination of this Agreement, you will immediately cease all use of the
              Service, including the API and the Payment Processing Services.
            </p>
            <p className="subsection-title">4.3 Trademark License</p>
            <p>
              Additionally, PayMongo grants you a revocable, non-exclusive, non-transferable license
              to use PayMongo’s trademarks used to identify the Payment Processing Services (the
              “Trademarks”) solely in conjunction with the use of the Payment Processing Services.
              You may only use the Trademarks on the portion of your website or application that
              directly relates to the Payment Processing Services, such as on a checkout page
              utilizing our Payment Processing Services. All Trademarks should directly link to
              our <Link to="/">homepage</Link>. PayMongo may, in the future, provide you with
              additional guidelines governing your use of the Trademarks.
            </p>
            <p>
              You agree that you will not at any time during or after this Agreement assert or claim
              any interest in or do anything that may adversely affect the validity of any Trademark
              or any other trademark, trade name or product designation belonging to or licensed to
              PayMongo (including, without limitation registering or attempting to register any
              Trademark or any such other trademark, trade name or product designation). You may not
              use the Trademarks to imply endorsement by PayMongo of your products or services, or
              in a manner that causes customer confusion. You may not misrepresent your relationship
              with PayMongo, or use the Trademarks in any manner that is misleading or damaging to
              PayMongo, in PayMongo’s sole discretion. In addition to any other right that we may
              have to enforce the terms of this Agreement, you must promptly comply with any request
              that we make for you to cease a use of the Trademarks that we determine is
              non-compliant with this paragraph. Upon expiration or termination of this Agreement,
              you will immediately cease all display, advertising and use of all of the Trademarks.
            </p>
            <p>
              As part of this Agreement, we do not make any representations regarding your use of
              the Trademarks. We disclaim all warranties, express and implied, including any
              warranties of non-infringement.
            </p>
          </div>

          <div id="section-c" className="section">
            <p className="section-header">Section C: Data Usage, Privacy, and Security</p>
          </div>

          <div id="cust-data" className="section">
            <p className="section-title">1. Customer Data</p>
            <p>
              In order for your Customers to process a Transaction through the Payment Processing
              Services, they will be required to provide you with certain personally identifiable
              information, which, in turn, you provide to us through your use of the Payment
              Processing Services. This information may include, but is not limited to:
            </p>
            <ul>
              <li key="cust-info-1">First and last name;</li>
              <li key="cust-info-2">Physical and/or mailing address;</li>
              <li key="cust-info-3">
                Financial information, including but not limited to credit or debit card numbers or
                bank account information;
              </li>
              <li key="cust-info-4">Email address; and,</li>
              <li key="cust-info-5">Phone number.</li>
            </ul>
            <p>
              Additionally, PayMongo may automatically collect information from Customers and their
              devices and browsers, including:
            </p>
            <ul>
              <li key="device-info-1">
                Device information, such as a unique device identifier; and,
              </li>
              <li key="device-info-2">
                Location information, such as your IP address or geo-location.
              </li>
            </ul>
            <p>
              All Customer Data shall be owned by you and you hereby grant PayMongo a perpetual,
              irrevocable, sub-licensable, assignable, worldwide, royalty-free license to use,
              reproduce, electronically distribute, and display Customer Data for the following
              purposes: (i) providing and improving the Services; (ii) internal usage, including but
              not limited to, data analytics and metrics so long as such Customer Data has been
              anonymized and aggregated with other customer data; (iii) complying with applicable
              legal requirements and assisting law enforcement agencies by responding to requests
              for the disclosure of information in accordance with applicable law; and (iv) any
              other purpose for which consent has been provided by the Customer. You hereby agree to
              undertake, and you acknowledge and understand that it is your sole responsibility to
              provide all notices to and obtain all consents from Customers necessary for PayMongo’s
              use of Customer Data as set out above.
            </p>
            <p>
              For the purposes of this Agreement, “Customer Data” means the personal data that (i)
              the Customer provides to you and you pass on to PayMongo through your use of the
              Payment Processing Services and (ii) PayMongo collects from the Customer’s device and
              browser through your use of the Payment Processing Services.
            </p>
          </div>

          <div id="publicity" className="section">
            <p className="section-title">2. Publicity</p>
            <p>
              You hereby grant PayMongo permission to use your name and logo in PayMongo’s marketing
              materials including, but not limited to use on PayMongo’s website, in customer
              listings, in interviews and in press releases.
            </p>
          </div>

          <div id="available-info" className="section">
            <p className="section-title">3. Information We Make Available to You</p>
            <p>
              As part of the Service, we may provide you with various information in furtherance of
              the Service. Our intention in doing so is to be helpful and to make the Service more
              useful to you. However, you agree that all information and suggestions that we provide
              to you through the Service is strictly for informational purposes and shall not be
              construed or relied upon in any way, and specifically shall not be construed or relied
              upon as professional advice of any kind. You agree that, to the fullest extent
              permitted by law, and as detailed below in the “Limitation of Liability” section of
              these Terms, we will not incur any liability at all whatsoever in the event that your
              reliance on any information provided by us results in harm or damage to you or your
              property.
            </p>
          </div>

          <div id="provided-info" className="section">
            <p className="section-title">4. Information You Provide to Us</p>
            <p>
              As a core aspect of the functionality of the Service, you may provide us with
              information about yourself, by giving us access to information collected by third
              parties, and by using the Service. By providing this information and content, you
              grant us the right to use the information and content for the purposes described in
              these Terms and our <Link to="/privacy">Privacy Policy</Link>. While we do not
              claim ownership over any such information and content that you provide, you agree that
              we have the right to use such information and content as specified in these Terms and
              our Privacy Policy. Further, by providing us with information and content through the
              Service, you represent and warrant that you own, or have the full legal authority to
              distribute, all information that you provide on the Service.
            </p>
            <p>
              In addition, we should not be relied upon as a means to store your data or information
              and we assume no responsibility for such activities.
            </p>
          </div>

          <div id="section-d" className="section">
            <p className="section-header">Section D: Use of the Service</p>
          </div>

          <div id="prohibited-content" className="section">
            <p className="section-title">1. Inapproriate and Illegal Content Prohibited</p>
            <p>
              You agree not to transmit any inappropriate content on the Service including, but not
              limited to, libelous, defamatory, obscene, pornographic, abusive, or threatening
              content; content that advocates or encourages conduct that could constitute a criminal
              offense, give rise to civil liability, or otherwise violate any applicable local,
              state, national, or foreign law or regulation; content that is misleading or not true;
              or advertise or otherwise solicits funds for goods or services. We may remove such
              content from our servers, and we may suspend or revoke your access to the Service, and
              we reserve the right to investigate, and seek applicable remedies for, violations of
              applicable law to the fullest extent of the law.
            </p>
          </div>

          <div id="notice-procedure" className="section">
            <p className="section-title">
              2. Notice and Procedure for Making U.S. Claims of Copyright or Trademark Infringement
            </p>
            <p>
              If you believe that your work has been copied in a way that constitutes copyright
              and/or trademark infringement, please provide a notice (a “DMCA Notice”) with the
              following information to PayMongo’s Copyright Agent:
            </p>
            <ol type="a">
              <li>
                An electronic or physical signature of the person authorized to act on behalf of the
                owner of the copyright or trademark interest;
              </li>
              <li>
                A detailed description of the copyright work or trademark that you claim has been
                infringed;
              </li>
              <li>
                A description of precisely where the material that you claim is being infringed is
                located on the Service;
              </li>
              <li>Your address, telephone number, and email address;</li>
              <li>
                A statement by you that you have a good faith belief that the disputed use is not
                authorized by the copyright or trademark owner, its agent or the law; and
              </li>
              <li>
                {`A statement by you, made under penalty of perjury, that the above information in
                your Notice is accurate and that you are the copyright or trademark owner or
                authorized to act on the copyright or trademark owner's behalf.`}
              </li>
            </ol>
            <p>PayMongo’s Copyright and Trademark Agent for DMCA Notices is:</p>
            <ul className="dmca-info">
              <li key="dmca-info-list-1">PayMongo</li>
              <li key="dmca-info-list-2">ATTN: DMCA Agent</li>
              <li key="dmca-info-list-3">2261 Market Street #4006</li>
              <li key="dmca-info-list-4">San Francisco, CA 94114 USA</li>
              <li key="dmca-info-list-5">E-Mail: dmca@paymongo.com</li>
            </ul>
            <p>
              {`We may give notice to our users by means of a general notice on our Service,
              electronic mail to a user's email address in our records, or by written communication
              sent by first-class mail to a user's physical address in our records. If you receive
              such a notice, you may provide counter-notification in writing to the designated
              Copyright and Trademark Agent. To be effective, the counter-notification must be a
              written communication that includes the following:`}
            </p>
            <ol type="a">
              <li>Your physical or electronic signature;</li>
              <li>
                Identification of the material that has been removed or to which access had been
                disabled, and the location at which the material appeared before it was removed or
                access to it was disabled;
              </li>
              <li>
                A statement from you under the penalty of perjury, that you have a good faith belief
                that the material was removed or disabled as a result of mistake to
                misidentification of the material to be removed or disabled; and
              </li>
              <li>
                Your name, physical address, and telephone number, and a statement that you consent
                to the jurisdiction of Federal District Court for the judicial district in which
                your physical address is located, or if your physical address is outside of the
                United States, for any judicial district in which PayMongo may be found, and that
                you will accept service or process from the person who provided notification of
                alleged infringing material or an agent of such person.
              </li>
            </ol>
            <p>
              {`PayMongo may terminate Accounts that have been the subject of five (5) separate DMCA
              notices. In the event a user's materials are removed due to a DMCA Notice and then
              subsequently restored due to the filing of a counter-notification, PayMongo will treat
              the underlying DMCA Notice as withdrawn. PayMongo reserves the right to terminate
              Accounts that are the subject of fewer than five (5) DMCA Notices in appropriate
              circumstances - such as where the user has a history of violating or willfully
              disregarding these Terms.`}
            </p>
          </div>

          <div id="ip-use" className="section">
            <p className="section-title">3. Your Use of Others' Intellectual Property</p>
            <p>
              Although you may provide information and content to PayMongo as part of your use of
              the Service, you agree to be respectful of others’ intellectual property rights. You
              may not upload, transmit, or otherwise distribute any information or content in
              violation of intellectual property laws or proprietary rights of any third parties. If
              you do not respect a third party’s intellectual property or proprietary rights, you
              are solely responsible for any violations of law. In addition, you are solely
              responsible for infringement of third-party rights caused by any information that is
              generated or submitted through your use of the Service. We take claims of intellectual
              property infringement seriously. As such, we reserve the right to suspend and/or
              revoke access to the Service for any user who is found to have infringed on the
              intellectual property rights of third parties, or us, or otherwise is found to have
              violated any intellectual property laws.
            </p>
          </div>

          <div id="third-party" className="section">
            <p className="section-title">4. Third Party Sites and Services</p>
            <p>
              Our Service is integrated with services provided by third parties as part of the
              functionality of the Service. We have no control over third parties and make no
              guarantees about, and assume no responsibility for, the information or services
              provided by third parties.
            </p>
            <p>
              Additionally, we may provide links to third-party websites and businesses and
              otherwise may provide information on the Service. You acknowledge and agree that we
              are not responsible for the accuracy, content, or functionality of services or
              information provided on or by such third-party websites or businesses or through such
              information on the Service, and we do not endorse, nor assume any liability related to
              your use of, such websites, businesses or information in any way. Nothing on the
              Service shall be considered an endorsement, representation, or warranty of anything
              posted by other users on the Service.
            </p>
          </div>

          <div id="termination" className="section">
            <p className="section-title">5. Termination of Your Use of the Service</p>
            <p>
              At any time, you may discontinue your use of the Service. Please visit
              our <Link to="/privacy">Privacy Policy</Link> to understand how we treat your
              information when you discontinue your use of the Service.
            </p>
            <p>
              We reserve the right to take action to limit or prevent your access to our Service, if
              we, in our sole discretion, deem that such action is necessary based on: your use of
              the Service in a way that would potentially expose us to liability; disruption of the
              Service by you to others; your violation of these Terms or our Privacy Policy; your
              violation of any applicable laws, rules, regulations, and agreements that may apply to
              you; and your use of the Service in a way that could cause harm to any person.
            </p>
            <p>
              Termination does not immediately relieve you of obligations incurred by you under this
              Agreement. Upon termination, you agree to (i) complete all pending Transactions, (ii)
              stop accepting new Transactions, and (iii) immediately remove all Trademarks from your
              website (unless permitted under a separate license with the payment network). Your
              continued or renewed use of the Services after all pending Transactions have been
              processed serves to renew your consent to the terms of this Agreement. If you
              terminate this Agreement, we will pay out any remaining funds owed to you subject to
              these Terms.
            </p>
            <p>
              After termination by either party, you shall no longer have access to, and shall cease
              all use of the Services. Any termination of this Agreement does not relieve you of any
              obligations to pay any Fees or any other financial obligation incurred by you or
              through your use of the Services prior to or after termination. In addition, all
              licenses granted to you by PayMongo under this Agreement will end.
            </p>
          </div>

          <div id="use-of-service" className="section">
            <p className="section-title">6. Proper Use of the Service</p>
            <p>
              You shall not violate or attempt to violate any security features of the Service,
              including, without limitation, (a) accessing content or data not intended for you, or
              logging onto a server that you are not authorized to access; (b) attempting to probe,
              scan, or test the vulnerability of the Service, or any associated system or network,
              or to breach security or authentication measures without proper authorization; (c)
              interfering or attempting to interfere with service to any user, host, or network,
              including, without limitation, by means of submitting a virus to the Service,
              overloading, “flooding,” “spamming,” “mail bombing,” “crashing,” or undertaking
              similar activities; (d) resell the Services or permit third parties to use the
              Services without our prior written consent; (e) make unauthorized copies of any
              content in the Services; and (f) forging any TCP/IP packet header or any part of the
              header information in any e-mail or in any posting using the Service.
            </p>
          </div>

          <div id="section-e" className="section">
            <p className="section-header">Section E: Additional Legal Terms</p>
          </div>

          <div id="assumption-of-risk" className="section">
            <p className="section-title">1. Assumption of Risk</p>
            <p>YOUR USE OF THE SERVICE IS ENTIRELY AT YOUR OWN RISK.</p>
            <p>
              YOU AGREE THAT IF YOU CHOOSE TO USE THE SERVICE, YOU ARE DOING SO VOLUNTARILY. YOU
              ASSUME ALL SUCH RISKS WITHOUT LIMITATION.
            </p>
          </div>

          <div id="disclaimer" className="section">
            <p className="section-title">2. Disclaimer of Warranties</p>
            <p>
              WE ARE MAKING THE SERVICE AVAILABLE “AS IS” AND, TO THE MAXIMUM EXTENT PERMITTED BY
              LAW, WE EXPRESSLY DISCLAIM ANY AND ALL WARRANTIES, EXPRESS OR IMPLIED, REGARDING THE
              SERVICE, INCLUDING, BUT NOT LIMITED TO, ANY IMPLIED WARRANTIES OF MERCHANTABILITY,
              FITNESS FOR A PARTICULAR PURPOSE, OR NONINFRINGEMENT. YOU ASSUME THE RISK OF ANY AND
              ALL DAMAGE OR LOSS FROM USE OF, OR INABILITY TO USE, THE SERVICE. WE DO NOT WARRANT
              THAT OPERATION OF THE SERVICE WILL BE UNINTERRUPTED OR ERROR-FREE.
            </p>
          </div>

          <div id="limit-of-liability" className="section">
            <p className="section-title">3. Limitation of Liability</p>
            <p>
              TO THE MAXIMUM EXTENT PERMITTED BY LAW, WE SHALL NOT BE LIABLE FOR DAMAGES OF ANY KIND
              (INCLUDING, BUT NOT LIMITED TO, SPECIAL, INCIDENTAL, OR CONSEQUENTIAL DAMAGES, LOST
              PROFITS, OR LOST DATA, REGARDLESS OF THE FORESEEABILITY OF THOSE DAMAGES) ARISING OUT
              OF OR IN CONNECTION WITH YOUR USE OF THE SERVICE INCLUDING, BUT NOT LIMITED TO,
              DAMAGES ARISING FROM OR RELATED TO ANY OF THE FOLLOWING: ANY CLAIM RELATED TO PERSONAL
              OR BODILY INJURY IN CONNECTION WITH THE USE OF THE SERVICE; ANY HARM OR DAMAGE CAUSED
              BY, OR OTHERWISE RELATED TO, YOUR RELIANCE ON INFORMATION PROVIDED THROUGH THE
              SERVICE; ANY LOSS OR UNAUTHORIZED DISCLOSURE OF DATA; ANY HARM OR DAMAGE CAUSED BY, OR
              OTHERWISE RELATED TO, YOUR USE OF OR INTERACTION WITH ANY SERVICES OFFERED THROUGH THE
              SERVICE; AND ANY VIOLATIONS OF APPLICABLE LAWS, RULES, REGULATIONS, AND OTHER
              AGREEMENTS. THIS LIMITATION SHALL APPLY REGARDLESS OF THE LEGAL THEORY OR FORM OF
              ACTION.
            </p>
            <p>
              IN NO EVENT WILL OUR AGGREGATE LIABILITY ARISING OUT OF OR IN CONNECTION WITH THESE
              TERMS AND YOUR USE OF THE SERVICE EXCEED ONE HUNDRED DOLLARS ($100.00).
            </p>
            <p>
              THE LIMITATIONS OF DAMAGES SET FORTH ABOVE ARE FUNDAMENTAL ELEMENTS OF THE BASIS OF
              THE BARGAIN BETWEEN YOU AND US.
            </p>
            <p>
              SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF LIABILITY FOR CERTAIN
              TYPES OF DAMAGES OR CAUSES OF ACTION, SO THE ABOVE LIMITATION MAY NOT APPLY TO YOU.
            </p>
          </div>

          <div id="indemnification" className="section">
            <p className="section-title">4. Indemnification</p>
            <p>
              In the event that any third party brings a claim against us related to your actions,
              content, information, or any other use of the Service by you, you agree to indemnify,
              defend, and hold us and our agents harmless from and against any and all third party
              claims, losses, liability, damages, and/or costs (including reasonable attorney fees
              and costs) relating to such claim. We will notify you promptly of any such claim and
              will provide you with reasonable assistance, at your expense, in defending any such
              claim, provided, however, that our failure to so notify you shall not relieve you of
              your indemnity obligations, but instead shall reduce those obligations by the amount
              of damages or increased costs and expenses attributable to our failure to give notice.
              We reserve the right to approve counsel retained by you, to take control of the
              defense (at our expense) of any claim for which indemnity is required, and to
              participate in the defense of any claim (at our expense) for which indemnity is
              required. You may not settle any claim without our prior consent.
            </p>
          </div>

          <div id="dispute-resolution" className="section">
            <p className="section-title">5. Dispute Resolution</p>
            <p>
              You agree to resolve any dispute, claim, or controversy with PayMongo arising out of
              or relating to your use in any way of the Service in the following manner. First, we
              both agree to attempt in good faith to resolve the dispute informally by contacting
              each other <a href="mailto:legal@paymongo.com">by email</a>. Second, if the dispute
              is not resolved through informal resolution, we both agree to attempt in good faith to
              resolve the dispute through mediation administered
              by <a href="https://www.jamsadr.com/">JAMS</a>, which shall take place in
              Makati City, Philippines, and the costs of which shall be divided equally between you
              and PayMongo. Third, if any such dispute is not resolved through informal resolution
              and mediation, we both agree to first refer such dispute for settlement through
              binding arbitration administered by JAMS, which shall take place in Makati City,
              Philippines.
            </p>
            <p>
              Either you or we may bring a lawsuit solely for injunctive relief without first
              engaging in the dispute resolution process described above.
            </p>
            <p>
              We both agree that, in the event of arbitration, or in the event of a lawsuit as
              permitted by this Section or otherwise, the prevailing party shall be entitled to
              costs and fees (including reasonable attorneys’ fees). Arbitration pursuant to this
              Section shall be confidential, and neither you, nor PayMongo, nor the arbitrator may
              disclose the existence, content or results of any arbitration, except as may be
              required by law or for purposes of enforcement or appeal of the arbitration award.
              Judgment on any arbitration award may be entered in any court having proper
              jurisdiction. There shall be no right or authority for any claims subject to this
              arbitration clause to be arbitrated on a class action or consolidated basis or on
              bases involving claims brought in a purported representative capacity on behalf of the
              general public (including, but not limited to, as a private attorney general). If any
              portion of this arbitration clause is determined by a court to be inapplicable or
              invalid, then the remainder shall still be given full force and effect.
            </p>
            <p>
              Nothing contained in this Section shall limit our ability to take action related to
              your access to the Service as provided in these Terms.
            </p>
          </div>

          <div id="govern-law" className="section">
            <p className="section-title">6. Governing Law, Venue, and Personal Jurisdiction</p>
            <p>
              These Terms shall be governed by the laws of the Republic of the Philippines, without
              regard to conflict of law provisions. In the event a lawsuit is filed subsequent to a
              failure of dispute resolution as provided under the immediately preceding provisions,
              we both agree that any judicial proceeding shall be brought exclusively in the
              appropriate courts of the City of Makati, Philippines.
            </p>
          </div>

          <div id="sever-waiver" className="section">
            <p className="section-title">7. Severability Waiver</p>
            <p>
              If, for whatever reason, any term or condition in these Terms is found unenforceable,
              all other terms and conditions will remain unaffected and in full force and effect.
              The failure to enforce any provision of these Terms is not a waiver of our right to do
              so later, and no waiver shall be effective unless made in writing and signed by an
              authorized representative of the waiving party.
            </p>
          </div>

          <div id="change-terms" className="section">
            <p className="section-title">8. Changes to These Terms</p>
            <p>
              PayMongo reserves the right to change these Terms from time to time, with or without
              notice to you. If you continue to use the Service, you consent to the new Terms. Any
              changes to these Terms will become effective on the “Effective Date” indicated above.
              If you continue to use the Service after the Effective Date, you consent to the new
              Terms. PayMongo will always have the latest Terms posted on the Service.
            </p>
          </div>

          <div id="contact-us" className="section">
            <p className="section-title">9. Contact Us</p>
            <p>
              If you have any questions about these Terms or our Service, please feel free to
              contact us by <a href="mailto:legal@paymongo.com">email</a>.
            </p>
          </div>

          <div id="headings" className="section">
            <p className="section-title">10. Headings Used in These Terms</p>
            <p>
              The section headings contained in these Terms are for reference purposes only and
              shall not affect the meaning or interpretation of these Terms in any way.
            </p>
          </div>
        </div>
      </div>
    </PageSection>
  </BaseTemplate>
);

export default IndexPage;
